import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import FlipCard from 'Components/FlipCard'
import ProgressBar from 'Components/ProgressBar'
import { StyledPageContainer } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import { AxiosResponse } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useAppointments from 'hooks/useAppointments'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useEnrollment from 'hooks/useEnrollment'
import useProfile from 'hooks/useProfile'
import React, { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { PhoneNumber } from 'tria-ui-react-lib/constants/ContactInfo.json'
import { ApiResponse } from 'types/ApiResponse'
import {
  AppointmentDate,
  AppointmentProgress,
  AppointmentRequest,
  AppointmentResponse,
  DemographicFormValues,
  InfoFormValues,
  Steps,
} from 'types/Appointment'
import image3 from '../../Assets/Images/Health Insurance-13 1.png'
import image1 from '../../Assets/Images/Med Review-03 1.png'
import image2 from '../../Assets/Images/Phone Call-23 1.png'
import image4 from '../../Assets/Images/Pill Money-27 1.png'
import './AppointmentPage.scss'
import AvailabilityForm, { AvailabilityFormValues } from './components/AvailabilityForm'
import CTLDemographicsForm from './components/CTLDemographicsForm'
import CalendarForm from './components/CalendarForm'
import ConfirmForm from './components/ConfirmForm'
import DemographicsForm from './components/DemographicsForm'
import IdForm from './components/IdForm'
import LifestyleForm, { LifestyleFormValues } from './components/LifestyleForm'
import ConsentForm from './components/PolicyForm'
import ProgramForm from './components/ProgramForm'

type Props = {
  isMobile: boolean
}

export default function AppointmentPage({ isMobile }: Props) {
  const { setDocumentTitle } = useDocumentTitle()
  const token = useAppSelector(getAccessToken)
  const { getAppointments, getInitialAppointments, postAppointment, postInitialAppointment, postRescheduledAppointment } = useAppointments(token!)
  setDocumentTitle('Appointment')

  const analytics = useAnalytics(token!)

  const [guid, setGuid] = useState<string>()
  const [welltrak, setWelltrak] = useState<string | undefined>()
  const [tempDemographics, setTempDemographics] = useState<DemographicFormValues>()
  const [tempCTLInfo, setTempCTLInfo] = useState<InfoFormValues>()
  const [skipCalendar, setSkipCalendar] = useState(false)
  const [signature, setSignature] = useState<string | undefined>()

  const [isInital, setIsInitial] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [appointmentLoading, setAppointmentLoading] = useState<boolean>(false)
  const [isCTL, setIsCTL] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const [availableAppointments, setAvailableAppointments] = useState<AppointmentDate[]>([])

  const [appointmentMessage, setAppointmentMessage] = useState<string | undefined>()
  const [appointmentTitle, setAppointmentTitle] = useState<string | undefined>()
  const [appointmentHelpText, setAppointmentHelpText] = useState<string | undefined>()
  const [scheduledAppointment, setScheduledAppointment] = useState<string | undefined>()
  const [scheduleId, setScheduleId] = useState<number | undefined>()
  const [justScheduled, setJustScheduled] = useState(false)
  const [consultLengthBool, setConsultLengthBool] = useState<boolean>(false)
  const [hasStop, setHasStop] = useState<boolean>(true)
  const [isInitialAppointment, setIsInitialAppointment] = useState<boolean>(true)

  const { getWelltrakFromGuid } = useProfile(token!)

  const { postCTLDemographics, postEnrollmentAvailability, postFollowUpAvailability, postCTLLifestyle } = useEnrollment()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [progress, setProgress] = useState<AppointmentProgress>({
    currentStep: 0,
    idStep: false,
    infoStep: false,
    policyStep: false,
    conditionStep: false,
    appointmentStep: false,
  })

  const postPageView = (page: string) => {
    analytics.postAnalyticsPageView(page)
  }

  const checkIfCTL = () => {
    const isCTL = searchParams.get('ctl') === 'true'
    if (isCTL !== undefined) {
      setIsCTL(isCTL)
    }
    if (isCTL) {
      changeStep(Steps.Demographics)
    }
  }

  const getWelltrak = (guid: string) => {
    getWelltrakFromGuid(guid).then((res: any) => {
      setWelltrak(res.data)
    })
  }

  const checkForGuid = () => {
    const id = searchParams.get('id')
    const ID = searchParams.get('ID')

    if (id !== null) {
      getWelltrak(id)
    }
    if (ID !== null) {
      getWelltrak(ID)
    }
  }

  useEffect(() => {
    dispatch(setLoading(true))
    checkIfCTL()
    checkForGuid()
    const reschedule = searchParams.get('reschedule')

    const randomNumber = Math.floor(Math.random() * 2)
    if (randomNumber === 1) {
      setConsultLengthBool(true)
    }

    if (token !== undefined && token !== '') {
      if (reschedule) {
        changeStep(Steps.Calendar)
      } else {
        getAppointments()
          .then((res: AxiosResponse<AppointmentResponse>) => {
            const { canSchedule, message, title, helpText, scheduledAppointment, scheduleId, isInitial } = res.data

            if (canSchedule === false) {
              changeStep(Steps.Confirm)
              message && setAppointmentMessage(message)
              title && setAppointmentTitle(title)
              scheduledAppointment && setScheduledAppointment(scheduledAppointment)
              scheduleId && setScheduleId(scheduleId)
              helpText && setAppointmentHelpText(helpText)
              isInitialAppointment && setIsInitialAppointment(isInitial)
            } else {
              changeStep(Steps.Calendar)
            }
            dispatch(setLoading(false))
          })
          .catch(() => {
            dispatch(setLoading(false))
            !isCTL && navigate('/error')
          })
      }
    } else {
      dispatch(setLoading(false))
    }
  }, [])

  useEffect(() => {
    const reschedule = searchParams.get('reschedule')
    if (isInital) {
      getInitialAppointments().then((res: AxiosResponse<AppointmentResponse>) => {
        setAvailableAppointments(res.data.appointmentDates)
      })
    } else if (token !== undefined && token !== '') {
      if (reschedule) {
        changeStep(Steps.Calendar)
      } else {
        getAppointments().then((res: AxiosResponse<AppointmentResponse>) => {
          const { canSchedule, message, title, helpText, scheduledAppointment, scheduleId } = res.data

          if (canSchedule === false) {
            changeStep(Steps.Confirm)
            message && setAppointmentMessage(message)
            title && setAppointmentTitle(title)
            scheduledAppointment && setScheduledAppointment(scheduledAppointment)
            scheduleId && setScheduleId(scheduleId)
            helpText && setAppointmentHelpText(helpText)
          }
          setAvailableAppointments(res.data.appointmentDates)
        })
      }
    }
  }, [isInital])

  const handleSuccess = (newStep: number) => {
    dispatch(setLoading(false))

    setErrorMessage(undefined)
    setProgress({
      ...progress,
      currentStep: newStep,
    })
    if (newStep === 0) {
      postPageView('/appointment')
    } else {
      postPageView(`/appointment/${Steps[newStep].toLowerCase()}`)
    }
    window.scrollTo(0, 0)
  }

  const handleError = (message?: string) => {
    if (message) {
      setErrorMessage(message)
    } else {
      setErrorMessage(`There was an error scheduling your appointment. Please contact the Tria Health Help Desk for additional help: ${PhoneNumber}`)
    }
    dispatch(setLoading(false))
  }

  const saveUserInfo = (userInfo: InfoFormValues) => {
    setAppointmentLoading(true)
    setTempCTLInfo(userInfo)
    userInfo.enrollmentFormGuid = guid

    postCTLDemographics(userInfo)
      .then((res: any) => {
        setAppointmentLoading(false)
        setErrorMessage(undefined)

        if (res.data.messages && res.data.messages.length > 0) {
          handleError(res.data.messages[0])
        } else {
          changeStep(Steps.Consent)
          window.scrollTo(0, 0)
        }
      })
      .catch(() => {
        setErrorMessage('There was an error. Please try again')
        setAppointmentLoading(false)
      })
  }

  const saveAvailabilityForm = (availabilityInfo: AvailabilityFormValues) => {
    setAppointmentLoading(true)
    dispatch(setLoading(true))

    availabilityInfo.enrollmentFormGuid = guid
    if (isInital) {
      postEnrollmentAvailability(availabilityInfo)
        .then(() => {
          setErrorMessage(undefined)
          setAppointmentLoading(false)
          changeStep(Steps.Confirm)
        })
        .catch(() => {
          setErrorMessage('There was an error. Please try again')
          setAppointmentLoading(false)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    } else {
      postFollowUpAvailability(availabilityInfo)
        .then(() => {
          setErrorMessage(undefined)
          setAppointmentLoading(false)
          changeStep(Steps.Confirm)
        })
        .catch(() => {
          setErrorMessage('There was an error. Please try again')
          setAppointmentLoading(false)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  const saveCalendarForm = (request: AppointmentRequest) => {
    setAppointmentLoading(true)
    dispatch(setLoading(true))
    setJustScheduled(true)

    if (isInital) {
      request.enrollmentFormGuid = guid
      postInitialAppointment(request)
        .then((res: AxiosResponse<ApiResponse>) => {
          if (res.data.success === true) {
            setAppointmentLoading(false)
            navigate(
              `/register?id=${welltrak}&lname=${tempDemographics?.lastName}&dob=${tempDemographics?.dateOfBirth}&email=${tempDemographics?.email}`,
            )
          } else {
            if (res.data.messages && res.data.messages.length > 0) {
              handleError(res.data.messages[0])
            } else {
              handleError()
            }
            setAppointmentLoading(false)
          }
        })
        .catch(() => {
          handleError()
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    } else {
      if (!scheduledAppointment) {
        postAppointment(request)
          .then((res: AxiosResponse<ApiResponse>) => {
            if (res.data.success === true) {
              setAppointmentLoading(false)
              changeStep(Steps.Confirm)
            } else {
              if (res.data.messages && res.data.messages.length > 0) {
                handleError(res.data.messages[0])
              } else {
                handleError()
              }
            }
          })
          .catch(() => {
            handleError()
          })
          .finally(() => {
            dispatch(setLoading(false))
          })
      } else {
        postRescheduledAppointment(request)
          .then((res: AxiosResponse<ApiResponse>) => {
            if (res.data.success === true) {
              setAppointmentLoading(false)
              changeStep(Steps.Confirm)
            } else {
              if (res.data.messages && res.data.messages.length > 0) {
                handleError(res.data.messages[0])
              } else {
                handleError()
              }
            }
          })
          .catch(() => {
            handleError()
          })
          .finally(() => {
            dispatch(setLoading(false))
          })
      }
    }
  }

  const saveLifestyleForm = (lifestyleInfo: LifestyleFormValues) => {
    setAppointmentLoading(true)
    dispatch(setLoading(true))

    lifestyleInfo.enrollmentFormGuid = guid
    postCTLLifestyle(lifestyleInfo)
      .then(() => {
        setAppointmentLoading(false)
        changeStep(Steps.Availability)
        window.scrollTo(0, 0)
      })
      .catch(() => {
        setErrorMessage('There was an error. Please try again')
        setAppointmentLoading(false)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const navigateRoute = () => {
    if (token) {
      changeStep(Steps.Calendar)
    } else {
      const now = new Date()

      const item = {
        value: '/appointment',
        expiry: now.getTime() + 1000 * 60 * 10,
      }
      window.localStorage.setItem('tempUrl', JSON.stringify(item))

      navigate('/')
    }
  }

  useEffect(() => {
    if (progress.currentStep === 5) {
      if (consultLengthBool) {
        if (isInital) {
          postPageView(`/appointment/initial/${Steps[5].toLowerCase()}/1`)
        } else {
          postPageView(`/appointment/${Steps[5].toLowerCase()}/1`)
        }
      } else {
        if (isInital) {
          postPageView(`/appointment/initial/${Steps[5].toLowerCase()}`)
        } else {
          postPageView(`/appointment/${Steps[5].toLowerCase()}/1`)
        }
      }
    }
  }, [progress])

  const changeStep = (newStep: number) => {
    window.scrollTo(0, 0)
    setErrorMessage(undefined)
    setProgress({ ...progress, currentStep: newStep })
    if (isInital) {
      postPageView(`/appointment/initial/${Steps[newStep].toLowerCase()}`)
    } else if (progress.currentStep === Steps.Confirm && newStep === Steps.Calendar) {
      postPageView(`/appointment/reschedule/${Steps[newStep].toLowerCase()}`)
    } else {
      postPageView(`/appointment/${Steps[newStep].toLowerCase()}`)
    }
  }

  const getStep = () => {
    const { currentStep } = progress
    switch (currentStep) {
      case Steps.Demographics:
        return (
          <DemographicsForm
            setTempFormInfo={(values) => {
              setTempDemographics(values)
            }}
            tempDemographics={tempDemographics}
            onBackPressed={() => handleSuccess(0)}
            loading={appointmentLoading}
            onSetGuid={(guid) => setGuid(guid)}
            onSuccess={() => handleSuccess(Steps.Id)}
            errorMessage={errorMessage}
            onError={() => handleError()}
          />
        )
      case Steps.Id:
        return (
          <IdForm
            guid={guid}
            onSuccess={() => handleSuccess(Steps.Consent)}
            onSetWelltrak={(id) => setWelltrak(id)}
            onSetSkipCalendar={(value) => setSkipCalendar(value)}
            welltrak={welltrak}
            onError={() => handleError()}
            loading={appointmentLoading}
            setHasStop={(hasStop: boolean) => setHasStop(hasStop)}
          />
        )
      case Steps.Consent:
        return (
          <ConsentForm
            loading={appointmentLoading}
            signature={signature}
            onSetSignature={(signature) => setSignature(signature)}
            onSuccess={() => handleSuccess(Steps.Program)}
            onError={() => handleError()}
            guid={guid}
            onBackPressed={() => changeStep(Steps.Id)}
          />
        )
      case Steps.Program:
        return (
          <ProgramForm
            loading={appointmentLoading}
            onBackPressed={() => changeStep(Steps.Consent)}
            onSuccess={(isStop) => {
              const skip = !welltrak || isStop || availableAppointments.length < 1 || skipCalendar
              if (skip) {
                changeStep(Steps.Availability)
              } else {
                changeStep(Steps.Calendar)
              }
            }}
            guid={guid}
            onError={() => handleError('There was an error. Please try again')}
            errorMessage={errorMessage}
            hasStop={hasStop}
          />
        )
      case Steps.Calendar:
        return (
          <CalendarForm
            onBackPressed={() => changeStep(Steps.Program)}
            onSetNoAppointment={() => changeStep(Steps.Availability)}
            saveCalendarForm={(request) => saveCalendarForm(request)}
            isInitial={isInital}
            loading={appointmentLoading}
            errorMessage={errorMessage}
            token={token}
            setScheduledAppointment={setScheduledAppointment}
            scheduledAppointment={scheduledAppointment}
            scheduleId={scheduleId}
            consultLengthBool={consultLengthBool}
          />
        )
      case Steps.Availability:
        return (
          <AvailabilityForm
            onBackPressed={() => {
              changeStep(skipCalendar ? Steps.Program : Steps.Calendar)
            }}
            loading={appointmentLoading}
            saveAvailabilityForm={saveAvailabilityForm}
          />
        )

      case Steps.Confirm:
        return (
          <ConfirmForm
            changeStep={changeStep}
            isInitial={isInital}
            justScheduled={justScheduled}
            scheduledAppointment={scheduledAppointment}
            title={appointmentTitle}
            helpText={appointmentHelpText}
            message={appointmentMessage}
            callText={skipCalendar}
            ctlText={isCTL}
          />
        )
      default:
        break
    }
  }

  const getCTLStep = () => {
    const { currentStep } = progress
    switch (currentStep) {
      case 1:
        return (
          <DemographicsForm
            setTempFormInfo={(values) => {
              setTempDemographics(values)
            }}
            tempDemographics={tempDemographics}
            onBackPressed={() => changeStep(0)}
            loading={appointmentLoading}
            onSetGuid={(guid) => setGuid(guid)}
            onSuccess={() => handleSuccess(2)}
            errorMessage={errorMessage}
            onError={() => handleError()}
          />
        )
      case 2:
        return (
          <CTLDemographicsForm
            onBackPressed={() => changeStep(1)}
            tempFormValues={tempCTLInfo}
            errorMessage={errorMessage}
            setUserInfo={(info: any) => saveUserInfo(info)}
            loading={appointmentLoading}
          />
        )
      case 3:
        return (
          <ConsentForm
            loading={appointmentLoading}
            signature={signature}
            onSetSignature={(signature) => setSignature(signature)}
            onSuccess={() => handleSuccess(Steps.Program)}
            onError={() => handleError()}
            guid={guid}
            onBackPressed={() => changeStep(2)}
          />
        )
      case 4:
        return (
          <LifestyleForm
            saveLifestyleForm={(values: any) => saveLifestyleForm(values)}
            onBackPressed={() => changeStep(3)}
            loading={appointmentLoading}
          />
        )
      case 6:
        return (
          <ConfirmForm
            changeStep={changeStep}
            isInitial={isInital}
            justScheduled={justScheduled}
            scheduledAppointment={scheduledAppointment}
            message={appointmentMessage}
            helpText={appointmentHelpText}
            callText={true}
            ctlText={isCTL}
          />
        )
      default:
        break
    }
  }

  return (
    <div>
      <>
        <div style={{ height: 50 }}></div>
        {progress.currentStep !== 0 &&
          progress.currentStep !== 7 &&
          (token ? (
            <></>
          ) : isCTL && progress.currentStep === 6 ? (
            <></>
          ) : (
            <ProgressBar
              currentStep={progress.currentStep}
              steps={
                isCTL
                  ? [
                      { title: 'Contact Info', id: 1 },
                      { title: 'Additional', id: 2 },
                      { title: 'Privacy Policy', id: 3 },
                      { title: 'Lifestyle', id: 4 },
                    ]
                  : [
                      { title: 'Contact Info', id: 1 },
                      { title: 'Tria ID', id: 2 },
                      { title: 'Privacy Policy', id: 3 },
                      { title: 'Program', id: 4 },
                      { title: 'Appointment', id: 5 },
                    ]
              }
            />
          ))}
        {progress.currentStep === 0 && (
          <StyledPage>
            {!token && (
              <>
                <Column>
                  <img
                    src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Pharmacist%20Phone%20Call-28%201.png"
                    className="appointment-banner-image"
                    alt="Man writing on calendar"
                    style={isMobile ? {} : { width: '50%', marginLeft: 'auto' }}
                  />

                  <div className="appointment-form">
                    <div className="appointment-header-container"></div>
                    <h1 className="appointment-header">Schedule an</h1>
                    <h1 className="appointment-subheader">Appointment</h1>
                    <Typography
                      styles={{ margin: '40px 0px', width: isMobile ? '100%' : '472px', textAlign: isMobile ? 'center' : 'left' }}
                      size={22}
                    >
                      Schedule a confidential phone consultation with a Tria Health pharmacist.
                    </Typography>

                    <StyledButton
                      pageName="pre_login_appointment"
                      text="I'm New to Tria Health!"
                      className="new-patient-btn"
                      styles={{ borderRadius: 0, width: isMobile ? '100%' : '400px' }}
                      onClick={() => {
                        postPageView(`/appointment/demographics`)
                        setIsInitial(true)
                        changeStep(Steps.Demographics)
                      }}
                    />
                    <div style={{ height: '25px' }}></div>
                    <StyledButton
                      pageName="pre_login_appointment"
                      text="I'm Scheduling a Follow Up"
                      className="follow-up-btn"
                      styles={{ backgroundColor: '#425563', borderRadius: 0, width: isMobile ? '100%' : '400px' }}
                      onClick={() => {
                        navigateRoute()
                      }}
                    />
                  </div>
                  <Row>
                    <ResponsiveContainer styles={{ width: isMobile ? '100%' : '83vw', justifyContent: 'space-between', marginTop: 50 }}>
                      <FlipCard
                        isMobile={isMobile}
                        side1={
                          <Typography
                            size={18}
                            color="white"
                            styles={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                          >
                            Your pharmacist will review all your meds: prescription, over-the counter, vitamins and/or supplements
                          </Typography>
                        }
                        side2={
                          <div style={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <img src={image1} alt="medication" style={{ margin: '12px 0px' }} />
                            <Typography font="bold" size={22}>
                              Review
                            </Typography>
                            <Typography font="bold" size={22} styles={{ marginTop: -5 }}>
                              Medications
                            </Typography>
                          </div>
                        }
                      />
                      <FlipCard
                        isMobile={isMobile}
                        side1={
                          <Typography
                            size={18}
                            color="white"
                            styles={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                          >
                            Talk with a medication expert and get answers for all your questions!
                          </Typography>
                        }
                        side2={
                          <div style={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <img src={image2} alt="medication" style={{ margin: '10px 0px 5px', height: 120 }} />
                            <Typography font="bold" size={22} styles={{ margin: 'auto' }}>
                              Get Answers
                            </Typography>
                          </div>
                        }
                      />
                      <FlipCard
                        isMobile={isMobile}
                        side1={
                          <Typography
                            size={18}
                            color="white"
                            styles={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                          >
                            Your Tria pharmacist will coordinate any recommendations directly with your doctor(s).
                          </Typography>
                        }
                        side2={
                          <div style={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <img src={image3} alt="medication" style={{ margin: '10px 0px 5px' }} />
                            <Typography font="bold" size={22} styles={{ margin: 'auto' }}>
                              Coordinate Care
                            </Typography>
                          </div>
                        }
                      />
                      <FlipCard
                        isMobile={isMobile}
                        side1={
                          <Typography
                            size={18}
                            color="white"
                            styles={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                          >
                            You may be missing out on medication savings! Let our pharmacists help.
                          </Typography>
                        }
                        side2={
                          <div style={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <img src={image4} alt="medication" style={{ margin: '10px 0px 5px' }} />
                            <Typography font="bold" size={22} styles={{ margin: 'auto' }}>
                              Save Money
                            </Typography>
                          </div>
                        }
                      />
                    </ResponsiveContainer>
                  </Row>
                </Column>
              </>
            )}
          </StyledPage>
        )}

        {isCTL ? getCTLStep() : getStep()}
      </>
    </div>
  )
}

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 85px;
  width: 96%;
  text-align: center;
  min-height: 600px;
  margin-left: 2%;
  margin-right: 2%;
  @media ${sizes.laptop} {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
  }
`
export const StyledPage = styled(StyledPageContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media ${sizes.laptop} {
    flex-direction: row;
  }
`
export const StyledButtonRow = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
  @media ${sizes.laptop} {
    flex-direction: row;
  }
`

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary1};
  font-family: 'Raleway-Semi-Bold';
  padding-top: 10px;
  width: 100%;
  padding: 10px 0px;
  border-radius: 4px;
`
// export const StyledCardText = styled.div`
//   width: 200px;
//   height: 188px;
//   color: white;
//   display: flex;
//   text-align: center;

// `
